import { defineStore } from 'pinia'
import { apiPatch, apiGet } from '~/services/api'
import { SELECTS, INPUTS, MAJOR_TYPES } from '~/helpers/dealTypes'
import { getLocalStorage } from '~/services/LocalStorage/localStorage'

interface dealData {
  client: { id: string; phone: string }
  created_at: string
  deal: {
    id: number
    type: { label: string; value: undefined }
    stages: { current_stage: undefined }
    agent_reward: number | undefined
    comment: string | undefined
  }
  name: string
  presentation: {
    counters: {
      objects_count: number
      records_to_watching_count: number
      views_count: number
    }
    created_at: string
    id: number
    code: string
  }
}

interface PatchReq {
  routeId: any
  type: string | undefined
  body: Object
  deal?: Object
}

export const dealStore = defineStore('dealStore', {
  state: () => ({
    boxHeadLoader: true,
    boxRestLoader: true,
    handleMapShowedUp: false,
    currentValue: undefined,
    currentTypes: undefined,
    viewMode: getLocalStorage('deals_view_mode') || 'kanban',
    currentTab: 'all',
    allTabDeals: [],
    refreshKey: 0,
    buttCounter: 0,
    thermometerTrigger: false,
    dealTableTransition: false,
    currentDealData: {
      client: { id: '', phone: '' },
      created_at: '',
      deal: { id: 0, type: { label: '', value: undefined }, agent_reward: undefined },
      name: '',
    } as dealData,

    dealOptions: {
      type: {
        label: 'Тип сделки:',
        data: [
          { type: 'purchase', label: 'Покупка' },
          { type: 'rent', label: 'Аренда' },
          { type: 'sell', label: 'Продажа' },
          { type: 'rent_out', label: 'Сдача' },
        ],
      },
      house: {
        label: 'Объект: ',
        current: undefined,
        data: MAJOR_TYPES,
      },
      //нет, не дебил, сказали что в дальнейшем каждый opts будет отличатся от другого, сделал максимально редактируемо
      // увлекательные приключения по json, сил тебе, брат, да прибудет с тобой сила, потому-что я так и не разобрался...
      condition: {
        label: 'Категория: ',
        current: undefined,
        data: [
          {
            id: 'Квартира',
            object_type: 'flat',
            opts: [
              {
                label: 'Вторичка',
                id: 'flat_resale',
                sell: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.living_area, INPUTS.m3.kitchen_area],

                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [
                      INPUTS.m3.areaCouple,
                      INPUTS.m3.living_areaCouple,
                      INPUTS.m3.kitchen_areaCouple,
                    ],
                    multi_select: [
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },

                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [
                      INPUTS.m3.areaCouple,
                      INPUTS.m3.living_areaCouple,
                      INPUTS.m3.kitchen_areaCouple,
                    ],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },

                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                    ],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.living_area, INPUTS.m3.kitchen_area],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
              },
              {
                label: 'Новостройка',
                id: 'flat_new_building',
                sell: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.living_area, INPUTS.m3.kitchen_area],
                    multi_select: [],
                    select: [
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },

                      {
                        label: 'Отделка',
                        value: undefined,
                        id: 'decoration',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.decoration,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [INPUTS.simple.floorsCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: '-is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [
                      INPUTS.m3.areaCouple,
                      INPUTS.m3.living_areaCouple,
                      INPUTS.m3.kitchen_areaCouple,
                    ],
                    multi_select: [
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },

                      {
                        label: 'Отделка',
                        value: undefined,
                        id: 'decoration',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.decoration,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [INPUTS.simple.floorsCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                    ],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [
                      INPUTS.m3.areaCouple,
                      INPUTS.m3.living_areaCouple,
                      INPUTS.m3.kitchen_areaCouple,
                    ],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },
                      {
                        label: 'Отделка',
                        value: undefined,
                        id: 'decoration',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.decoration,
                      },

                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },

                      {
                        label: 'Апартаменты',
                        value: undefined,
                        id: 'is_apartments',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_apartments,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.living_area, INPUTS.m3.kitchen_area],
                    multi_select: [
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },

                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Комнат',
                        value: undefined,
                        id: 'rooms',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rooms,
                      },
                      {
                        label: 'Отделка',
                        value: undefined,
                        id: 'decoration',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.decoration,
                      },
                    ],
                  },
                },
              },
            ],
          },

          {
            id: 'Комната',
            object_type: 'room',
            opts: [
              {
                label: 'В квартире',
                id: 'room_in_flat',
                sell: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Соседи',
                        value: undefined,
                        id: 'neighbours',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.neighbours,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                      {
                        label: 'Соседи',
                        value: undefined,
                        id: 'neighbours',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.neighbours,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Соседи',
                        value: undefined,
                        id: 'neighbours',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.neighbours,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                      {
                        label: 'Соседи',
                        value: undefined,
                        id: 'neighbours',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.neighbours,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
              },
              {
                label: 'В общежитии',
                id: 'room_in_dormitory',
                sell: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
              },
              {
                label: 'В коммуналке',
                id: 'room_in_communal',
                sell: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [INPUTS.simple.floorsCouple, INPUTS.simple.built_yearCouple],
                    inputM3: [],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [
                      {
                        label: 'Пешком до метро',
                        value: undefined,
                        id: 'time_to_metro',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_metro,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple, INPUTS.m3.kitchen_areaCouple],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },

                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [INPUTS.simple.floors_new_design, INPUTS.simple.built_year],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'house_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.house_type,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.kitchen_area],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    multi_select: [
                      {
                        label: 'Мебель',
                        value: undefined,
                        id: 'furniture',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.furniture,
                      },
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Ремонт',
                        value: undefined,
                        id: 'renovation',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.renovation,
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'Дом/Дача/Коттедж/Таунхаус',
            object_type: 'house',
            opts: [
              {
                label: 'Дом',
                id: 'house',
                sell: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price, INPUTS.simple.floors_in_house],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floors_in_house,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    multi_select: [
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
              },
              {
                label: 'Дача',
                id: 'dacha',
                sell: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price, INPUTS.simple.floors_in_house],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floors_in_house,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    multi_select: [
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
              },
              {
                label: 'Коттедж',
                id: 'cottage',
                sell: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price, INPUTS.simple.floors_in_house],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floors_in_house,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    multi_select: [
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
              },
              {
                label: 'Танхаус',
                id: 'townhouse',
                sell: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Ключи на руках',
                        id: 'keys',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [INPUTS.simple.price, INPUTS.simple.floors_in_house],
                    multi_select: [],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    inputM3: [INPUTS.m3.areaCouple],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    multi_select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип',
                        value: undefined,
                        id: 'land_status',
                        ref: ref(),
                        state: false,
                        opts: [
                          { label: 'ИЖС', value: 'igs' },
                          { value: 'snt', label: 'СНТ' },
                        ],
                      },
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Коммунальные услуги',
                        value: undefined,
                        id: 'is_communication_price_included',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_communication_price_included,
                      },
                      {
                        label: 'Ключи на руках',
                        value: undefined,
                        id: 'keys',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.keys,
                      },
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    inputM3: [INPUTS.m3.area],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floors_in_house,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    multi_select: [
                      {
                        label: 'Кого заселяют',
                        value: undefined,
                        id: 'possible_tenant',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.possible_tenant,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Материал стен',
                        id: 'walls_type',
                        value: undefined,
                        ref: ref(),
                        state: false,
                        opts: SELECTS.walls_type,
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'Земельный участок',
            object_type: 'earth',
            opts: [
              {
                label: 'ИЖС',
                id: 'igs',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },

                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
              },
              {
                label: 'СНТ',
                id: 'snt',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.area, INPUTS.m3.land_areaCouple],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },

                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.land_area],
                    multi_select: [
                      {
                        label: 'Доп. строения',
                        value: undefined,
                        id: 'additions',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.additions,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications.filter((item) => item.value !== 'heating'),
                      },
                    ],
                    select: [
                      {
                        label: 'Время до города',
                        value: undefined,
                        id: 'time_to_city',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.time_to_city,
                      },
                    ],
                  },
                },
              },
            ],
          },
          {
            id: 'Коммерческая',
            object_type: 'commercial',
            opts: [
              {
                label: 'Офис',
                id: 'office',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Торговая площадь',
                id: 'shopping',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Свободного назначения',
                id: 'free_appoint',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Склад',
                id: 'warehouse',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Производство',
                id: 'industry',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Общепит',
                id: 'cook',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Гостиница',
                id: 'hotel',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Автосервис',
                id: 'autoservice',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
              {
                label: 'Здание целиком',
                id: 'building',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price, INPUTS.simple.floor],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [],
                    select: [],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple, INPUTS.simple.floorsCouple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                      {
                        label: 'Этаж',
                        value: undefined,
                        id: 'floors',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.floors,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [
                      INPUTS.simple.price,
                      INPUTS.simple.floor,
                      INPUTS.simple.zalog,
                      INPUTS.simple.deposit,
                    ],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
              },
            ],
          },
          {
            id: 'Гараж/Машино-место',
            object_type: 'garage',
            opts: [
              {
                label: 'Гараж',
                id: 'garage',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'Удобства',
                        value: undefined,
                        id: 'garage_facilities',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_facilities,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип гаража',
                        value: undefined,
                        id: 'garage_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Удобства',
                        value: undefined,
                        id: 'garage_facilities',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_facilities,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип гаража',
                        value: undefined,
                        id: 'garage_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Удобства',
                        value: undefined,
                        id: 'garage_facilities',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_facilities,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },

                      {
                        label: 'Тип гаража',
                        value: undefined,
                        id: 'garage_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'Удобства',
                        value: undefined,
                        id: 'garage_facilities',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_facilities,
                      },
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип гаража',
                        value: undefined,
                        id: 'garage_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.garage_type,
                      },
                    ],
                  },
                },
              },
              {
                label: 'Машино-место',
                id: 'car_place',
                sell: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель продажи',
                        value: undefined,
                        id: 'sell_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.sell_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип машино-места',
                        value: undefined,
                        id: 'car_place_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.car_place_type,
                      },
                    ],
                  },
                },
                purchase: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [
                      {
                        label: 'Цель покупки',
                        value: undefined,
                        id: 'purchase_purpose',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.purchase_purpose,
                      },
                    ],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'Тип машино-места',
                        value: undefined,
                        id: 'car_place_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.car_place_type,
                      },
                    ],
                    select: [],
                  },
                },
                rent: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [],
                    input: [INPUTS.simple.price_couple],
                    inputM3: [INPUTS.m3.areaCouple],
                    multi_select: [
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },

                      {
                        label: 'Тип машино-места',
                        value: undefined,
                        id: 'car_place_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.car_place_type,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [],
                  },
                },
                rent_out: {
                  about: {
                    input: [],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  condition: {
                    input: [INPUTS.simple.agent_reward, INPUTS.simple.comment],
                    inputM3: [],
                    multi_select: [],
                    select: [],
                  },
                  all: {
                    butts_select_alone: [
                      {
                        label: 'Субаренда',
                        value: undefined,
                        id: 'is_subrent',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.is_subrent,
                      },
                    ],
                    input: [INPUTS.simple.price, INPUTS.simple.deposit, INPUTS.simple.zalog],
                    inputM3: [INPUTS.m3.area],
                    multi_select: [
                      {
                        label: 'Коммуникации',
                        value: undefined,
                        id: 'communications',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.communications,
                      },
                      {
                        label: 'На какой срок',
                        value: undefined,
                        id: 'rental_period',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.rental_period,
                      },
                    ],
                    select: [
                      {
                        label: 'Тип машино-места',
                        value: undefined,
                        id: 'car_place_type',
                        ref: ref(),
                        state: false,
                        opts: SELECTS.car_place_type,
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    },
    dealDataTypes: {
      object_type: undefined,
      object_sub_type: undefined,
    },

    dealData_sell: {
      secured: undefined,
      object_type: undefined,
      object_sub_type: undefined,
      house_type: undefined,
      price: undefined,
      area: undefined,
      land_area: undefined,
      kitchen_area: undefined,
      rooms: undefined,
      floor: undefined,
      floors: undefined,
      renovation: undefined,
      time_to_city: undefined,
      lat: undefined,
      lon: undefined,
      built_year: undefined,
      walls_type: undefined,
      decoration: undefined,
      keys: undefined,
      land_status: undefined,
      address_raw: undefined,
      address_formatted: undefined,
      description: undefined,
      is_apartments: undefined,
      action_type: undefined,
      garage_type: undefined,
      car_place_type: undefined,
      communications: [],
      additions: [],
      furniture: [],
      living_area: undefined,
      is_communication_price_included: undefined,
      is_subrent: undefined,
      deposit: undefined,
      zalog: undefined,
      neighbours: undefined,
      possible_tenant: [],
      rental_period: [],
      garage_facilities: [],
      sell_purpose: undefined,
    },
    dealData_rent_out: {
      secured: undefined,
      object_type: undefined,
      object_sub_type: undefined,
      house_type: undefined,
      price: undefined,
      area: undefined,
      land_area: undefined,
      kitchen_area: undefined,
      rooms: undefined,
      floor: undefined,
      floors: undefined,
      renovation: undefined,
      time_to_city: undefined,
      lat: undefined,
      lon: undefined,
      built_year: undefined,
      walls_type: undefined,
      decoration: undefined,
      keys: undefined,
      land_status: undefined,
      address_raw: undefined,
      address_formatted: undefined,
      description: undefined,
      is_apartments: undefined,
      action_type: undefined,
      garage_type: undefined,
      car_place_type: undefined,
      communications: [],
      additions: [],
      furniture: [],
      living_area: undefined,
      is_communication_price_included: undefined,
      is_subrent: undefined,
      deposit: undefined,
      zalog: undefined,
      neighbours: undefined,
      possible_tenant: [],
      rental_period: [],
      garage_facilities: [],
    },
    dealData_rent: {
      object_type: undefined,
      price_from: undefined,
      price_to: undefined,
      area_from: undefined,
      area_to: undefined,
      land_area_from: undefined,
      land_area_to: undefined,
      kitchen_area_from: undefined,
      kitchen_area_to: undefined,
      floor_from: undefined,
      floor_to: undefined,
      floors_from: undefined,
      floors_to: undefined,
      built_year_from: undefined,
      built_year_to: undefined,
      multipolygon: null,
      object_sub_type: [],
      walls_types: [],
      house_type: [],
      garage_types: [],
      rooms: [],
      decorations: [],
      renovation: [],
      car_place_types: [],
      communications: [],
      additions: [],
      furniture: [],
      land_statuses: [],
      is_apartments: [],
    },
    dealData_purchase: {
      object_type: undefined,
      price_from: undefined,
      price_to: undefined,
      area_from: undefined,
      area_to: undefined,
      land_area_from: undefined,
      land_area_to: undefined,
      kitchen_area_from: undefined,
      kitchen_area_to: undefined,
      floor_from: undefined,
      floor_to: undefined,
      floors_from: undefined,
      floors_to: undefined,
      built_year_from: undefined,
      built_year_to: undefined,
      multipolygon: null,
      object_sub_type: [],
      walls_types: [],
      house_type: [],
      garage_types: [],
      rooms: [],
      decorations: [],
      renovation: [],
      car_place_types: [],
      communications: [],
      additions: [],
      furniture: [],
      land_statuses: [],
      is_apartments: [],
      purchase_purpose: undefined,
    },
  }),

  actions: {
    async patchDealTEST({ routeId, type, body, deal }: PatchReq) {
      const action = {
        ...deal,
        [type]: {
          ...body,
        },
      }
      try {
        const response = await apiPatch({
          url: `api/deal/${routeId}`,
          body: { ...action, type: type },
        })
        const { data } = response as unknown as { data: any }
        await this.fetchSearchButt(routeId)
        if (!data && !data.value) return null
        this.thermometerTrigger = false
        return data.value
      } catch (e) {
        console.log(e)
      }
    },

    async fetchDeals(routeID: string | string[]) {
      this.boxHeadLoader = true
      try {
        const { data } = await apiGet({ url: `api/deal/${routeID}` })
        if (!data.value) throw showError({ statusCode: 404, message: 'Страница не найдена =(' })
        const dealData = (data as any).value.data
        this.currentDealData = dealData
        const dataValues = (data as any).value.data
        this.dealData_sell = dataValues.sell ? dataValues.sell : this.dealData_sell
        this.dealData_rent = dataValues.rent ? dataValues.rent : this.dealData_rent
        this.dealData_rent_out = dataValues.rent_out ? dataValues.rent_out : this.dealData_rent_out
        this.dealData_purchase = dataValues.purchase ? dataValues.purchase : this.dealData_purchase
        this.fetchLines(routeID)
      } catch (e) {
        console.log(e)
      } finally {
        this.boxHeadLoader = false
      }
    },
    async fetchLines(routeID: string | string[]) {
      this.boxRestLoader = true
      try {
        const { data } = await apiGet({ url: `api/deal/${routeID}` })
        if (data.value && data.value.data) {
          await this.fetchSearchButt(routeID)
          const dataValues = data.value.data
          this.dealData_sell = dataValues.sell ? dataValues.sell : this.dealData_sell
          this.dealData_rent = dataValues.rent ? dataValues.rent : this.dealData_rent
          this.dealData_rent_out = dataValues.rent_out
            ? dataValues.rent_out
            : this.dealData_rent_out
          this.dealData_purchase = dataValues.purchase
            ? dataValues.purchase
            : this.dealData_purchase
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.boxRestLoader = false
      }
    },
    async fetchSearchButt(routeID: string | string[]) {
      try {
        const { data } = await apiGet({ url: `api/deal/get-object-count-button/${routeID}` })
        if (!data.value) return
        this.buttCounter = data.value?.object_founded
      } catch (e) {
        console.log(e)
      }
    },

    getDealObjSell(dealData: any, type: string) {
      const { object_sub_type, object_type } = dealData[type] || {}
      return { object_sub_type, object_type }
    },
    getDealObjpurchase(dealData: any, type: string) {
      const { object_sub_type, object_type } = dealData[type] || {}
      return { object_sub_type, object_type }
    },
    getObjectSubType(object_type: string) {
      if (object_type === 'earth' || object_type === 'commercial') {
        return null
      }
      return this.dealDataTypes.object_sub_type // в данном случае sell
    },

    setObjectSubTypeIfUndefined(dealData: any) {
      if (!dealData.object_sub_type) {
        if (dealData.object_type === 'earth') {
          dealData.object_sub_type = 'earth'
        } else if (dealData.object_type === 'commercial') {
          dealData.object_sub_type = 'commercial'
        }
      }
    },
  },

  getters: {
    dealData(state) {
      return {
        sell: state.dealData_sell,
        rent_out: state.dealData_rent_out,
        purchase: state.dealData_purchase,
        rent: state.dealData_rent,
      }
    },
  },
})
